@import "../../../variables.scss";

.summaryDropdown {
	display: flex;
	align-items: center;

	.label {
		color: $dark-grey;
		white-space: nowrap;
		margin-right: 10px;
	}

	@include media-mobile {
		flex-direction: column;
		width: 100%;
		align-items: flex-start;
		.label {
			margin-bottom: 10px;
		}
	}
}
