@import "../../../../../variables.scss";

.item {
	border: 1px solid $grey-light;
	display: flex;
	margin-bottom: 10px;
	.recommendationPanel {
		width: 67%;
		padding: 20px;
		display: flex;

		.recommendationNumber {
			font-size: 24px;
			text-align: right;
			width: 30px;
			color: $dark-grey;
			margin-right: 20px;
		}
		.recommendation {
			& > .label {
				font-weight: 500;
				margin-bottom: 10px;
				color: $dark-grey;
				@include media-mobile {
					padding-right: 20px;
				}
			}
			.recommendationText {
				font-weight: 300;
				color: $dark-grey;
				line-height: 1.5em;
				color: $dark-grey;
			}
			.details {
				margin-top: 20px;
				.detailItem {
					display: flex;
					align-items: baseline;
					.label {
						width: 40%;
					}
					.value {
						width: 60%;
						font-weight: 300;
					}
					@include media-mobile {
						flex-direction: column;
						.label,
						.value {
							width: 100%;
						}
						.label {
							margin: 10px 0 5px;
						}
					}
				}
			}
		}
		@include media-mobile {
			width: auto;
			flex-grow: 1;
		}
	}
	.responsePanel {
		width: 33%;
		padding: 20px;
		background: $blue-light-background;
		.statusContainer {
			position: relative;
			margin: 10px 0 30px;
			padding-left: 24px;
			.status {
				font-size: 24px;
				color: $dark-grey;
				&:before {
					content: " ";
					width: 18px;
					height: 18px;
					background: green;
					position: absolute;
					left: 0;
					top: 0;
					box-sizing: border-box;
				}
				&[data-status="Fully implemented"] {
					&:before {
						background: $blue-pastel;
					}
				}
				&[data-status="Partially implemented"] {
					&:before {
						background: #fff;
						border: $blue-pastel solid 1px;
					}
				}
				&[data-status="No longer applicable"] {
					&:before {
						background: $brown-pastel;
					}
				}
				&[data-status="Not implemented"] {
					&:before {
						background: $brown;
					}
				}
				@include media-mobile {
					font-size: 21px;
				}
			}
		}
		.detailItem:last-child {
			margin-top: 20px;
		}
		@include media-mobile {
			width: auto;
			flex-grow: 1;
		}
	}

	.detailItem {
		font-size: 16px;
		line-height: 1.3em;
		.label {
			font-weight: 500;
			margin: 10px 0;
			color: $dark-grey;
		}
		.value {
			line-height: 1.5em;
			font-weight: 300;
			color: $dark-grey;
			display: inline;
			&[data-unnamed="true"] {
				border-bottom: 1px dashed $dark-grey;
				cursor: pointer;
			}
		}
		.popup {
			opacity: 1 !important;
			width: 200px;
			border: 1px solid $grey-light;
			background: $blue-light-background;
			padding: 10px;
		}
	}
	@include media-mobile {
		position: relative;
		padding-bottom: 70px;
		overflow: hidden;
		&[data-expanded="false"] {
			height: 160px;
			box-sizing: content-box;
		}
		&[data-expanded="true"] {
			.moreDetail {
				background: none;
			}
		}
		.moreDetail {
			position: absolute;
			bottom: 0;
			right: 0;
			left: 0;
			background: linear-gradient(360deg, #fff 50%, rgba(255, 255, 255, 0) 100%);
			height: 70px;
			.button {
				font-weight: normal;
				width: calc(100% - 30px);
				border-radius: 6px;
				margin: 15px;
				color: $blue;
			}
		}
		.recommendationPanel {
			.mobileStatusSquare {
				position: absolute;
				background: green;
				top: 18px;
				right: 18px;
				width: 15px;
				height: 15px;
				text-indent: 10000px;
				white-space: nowrap;
				box-sizing: border-box;
				&[data-status="Fully implemented"] {
					background: $blue-pastel;
				}
				&[data-status="Partially implemented"] {
					background: #fff;
					border: $blue-pastel solid 1px;
				}
				&[data-status="No longer applicable"] {
					background: $brown-pastel;
				}
				&[data-status="Not implemented"] {
					background: $brown;
				}
			}
		}
	}
	@include media-mobile {
		flex-direction: column;
	}
}
