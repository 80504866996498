@import "../../../../variables.scss";

.group {
	display: flex;
	align-items: flex-start;
	border-top: 1px solid $grey-light;
	padding: 20px 0;
	&:last-child {
		border-bottom: 1px solid $grey-light;
	}
	.itemExpando {
		width: 18px;
		height: auto;
		background: none;
		padding: 0;
		border: none;
		margin-right: 8px;

		svg {
			margin-top: 4px;
			width: 18px;
			min-width: 18px;
			height: auto;
			color: $blue;
		}
		@include media-mobile {
			width: 18px;
			svg {
				width: 18px;
			}
		}
	}
	.groupContainer {
		flex-grow: 1;
		margin-left: 10px;
		.groupCollapsed {
			.groupHeading {
				color: $blue;
				font-size: 18px;
				line-height: 1.5em;
				font-weight: 400;
				margin-top: 0;
				padding-right: 34%;
				margin-bottom: 15px;
				display: flex;
				.headingIcon {
					margin-right: 10px;
					width: 30px;
					svg {
						height: 24px;
						width: auto;
						max-width: 30px;
					}
				}
				.headingLabel {
					flex-grow: 0;
				}
				@include media-mobile {
					flex-grow: 1;
					font-size: 16px;
					margin-bottom: 0;
					padding-right: 0;
				}
			}
			.groupStats {
				display: flex;
				max-width: 100%;
				.groupStatsChart {
					width: 67%;
					margin-top: 5px;
					padding-right: 10px;
					margin-bottom: 10px;
					@include media-mobile {
						flex-grow: 1;
						width: auto;
					}
				}
				.collapsedDetails {
					display: flex;
					flex-grow: 1;
				}
				.groupStatsFully,
				.groupStatsRecommendations {
					width: 50%;
					.value {
						display: block;
						font-size: 21px;
					}
					.label {
						display: block;
						font-size: 16px;
						color: $grey-medium;
						margin-top: 10px;
					}
					@include media-mobile {
						.value {
							font-size: 18px;
						}
						.label {
							font-size: 14px;
							margin-top: 5px;
						}
					}
				}
				.expandedDetails {
					display: flex;
					flex-direction: column;
					.expandedDetailItem {
						display: flex;
						margin: 5px 0;
						.label {
							display: block;
							font-size: 21px;
							position: relative;
							margin-right: 10px;
							&:before {
								content: " ";
								width: 12px;
								height: 12px;
								background: green;
								position: absolute;
								left: 0;
								top: 3px;
								box-sizing: border-box;
							}
							&[data-status="recommendations"] {
								&:before {
									content: none;
								}
							}
							&[data-status="Fully implemented"] {
								padding-left: 18px;
								&:before {
									background: $blue-pastel;
								}
							}
							&[data-status="Partially implemented"] {
								padding-left: 18px;
								&:before {
									background: #fff;
									border: $blue-pastel solid 1px;
								}
							}
							&[data-status="No longer applicable"] {
								padding-left: 18px;
								&:before {
									background: $brown-pastel;
								}
							}
							&[data-status="Not implemented"] {
								padding-left: 18px;
								&:before {
									background: $brown;
								}
							}
						}
						.value {
							font-size: 16px;
							color: $grey-medium;
						}
						&:first-child {
							margin-bottom: 5px;
						}
						@include media-mobile {
							.label {
								font-size: 18px;
							}
							.value {
								font-size: 14px;
							}
						}
					}
				}
				.auditYears {
					font-weight: 500;
					display: flex;
					align-items: baseline;
					margin-top: 10px;
					margin-right: 10px;
					.auditYearsList {
						display: flex;
						align-items: baseline;
						.year {
							background: #28a745;
							font-family: "Roboto Condensed", Arial, Verdana, sans-serif;
							padding: 6px;
							font-size: 0.875rem;
							color: #fff;
							border-radius: 4px;
							margin-right: 10px;
						}
					}
					@include media-mobile {
						flex-direction: column;
						.auditYearsList {
							margin-top: 10px;
						}
					}
				}
				@include media-mobile {
					flex-direction: column;
				}
			}
		}
		.groupExpanded {
			padding-top: 20px;
			.groupExpandedSummary {
				font-size: 21px;
				margin-bottom: 10px;
				@include media-mobile {
					font-size: 18px;
					margin-bottom: 15px;
				}
			}
			.reportGroupHeading {
				font-weight: 600;
				font-size: 16px;
				margin: 30px 0 20px;
			}
			.itemsContainer {
				display: flex;
				flex-direction: column;
				@include media-mobile {
					margin-left: -10px;
				}
			}
		}
	}
	&:hover {
		.itemExpando {
			cursor: pointer;
		}
		.groupHeading {
			text-decoration: underline;
			color: $blue-darker;
			cursor: pointer;
		}
	}
}
