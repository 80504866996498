@import "../../../variables.scss";

.labelSmall {
	text-transform: uppercase;
	font-size: 12px;
	color: $grey-medium;
	margin: 0;
	font-weight: normal;
}

.recommendations {
	display: flex;
	.recommendationsNumber {
		min-width: $summary-left-width;
		display: flex;
		.recommendationsNumberIcon {
			width: 54px;
			height: 54px;
		}
		.recommendationsNumberLabel {
			display: flex;
			flex-direction: column;
			padding-left: 15px;
			.number {
				font-size: 36px;
				font-weight: 300;
				color: $dark-grey;
				margin: 10px 0 10px;
			}
		}
	}
	.implementationStatus {
		flex-grow: 1;
		.statusContainer {
			padding-top: 20px;
			.aboveChart,
			.belowChart {
				display: flex;
				@include media-mobile {
					flex-direction: column;
					width: 100%;
				}
			}
			.belowChart {
				padding-top: 10px;
				justify-content: flex-end;
				.statusItem {
					margin: 10px 0 10px 20px;
				}
			}
			.statusItem {
				margin: 10px 20px 10px 0;
				.percentage {
					font-size: 21px;
					position: relative;
					&:before {
						content: " ";
						width: 12px;
						height: 12px;
						background: green;
						position: absolute;
						left: 0;
						top: 3px;
						box-sizing: border-box;
					}
					&[data-status="recommendations"] {
						&:before {
							content: none;
						}
					}
					&[data-status="Fully implemented"] {
						padding-left: 18px;
						&:before {
							background: $blue-pastel;
						}
					}
					&[data-status="Partially implemented"] {
						padding-left: 18px;
						&:before {
							background: #fff;
							border: $blue-pastel solid 1px;
						}
					}
					&[data-status="No longer applicable"] {
						padding-left: 18px;
						&:before {
							background: $brown-pastel;
						}
					}
					&[data-status="Not implemented"] {
						padding-left: 18px;
						&:before {
							background: $brown;
						}
					}
					@include media-mobile {
						font-size: 18px;
					}
				}
				.statusAndNumber {
					color: $grey-medium;
					font-size: 16px;
					margin: 6px 0 10px;
					text-transform: lowercase;
					border-bottom: 1px dashed $dark-grey;
					cursor: pointer;
					@include media-mobile {
						margin-top: 2px;
						font-size: 14px;
						display: inline;
					}
				}
			}
			@include media-mobile {
				display: flex;
				flex-direction: column;
				padding-top: 10px;
				.aboveChart {
					order: 2;
					.statusItem {
						margin: 10px 0 0;
					}
				}
				.theChart {
					order: 1;
				}
				.belowChart {
					order: 3;
					.statusItem {
						margin: 10px 0 0;
					}
				}
			}

			.popup {
				opacity: 1 !important;
				width: 200px;
				border: 1px solid $grey-light;
				background: $blue-light-background;
				padding: 10px;
			}
		}
		@include media-mobile {
			margin-top: 20px;
		}
	}
	@include media-mobile {
		flex-direction: column;
	}
}
