@import "../../../../variables.scss";

.filters {
	margin-top: 30px;
	.filterHeading {
		display: flex;
		align-items: center;
		.filterButton {
			display: flex;
			align-items: center;
			.chevronDown,
			.chevronUp,
			.filterIcon {
				width: auto;
				height: 22px;
			}
			.chevronDown,
			.chevronUp {
				margin-right: 10px;
			}
			.filterIcon {
				margin-left: 20px;
			}
			font-weight: normal;
			font-size: 16px;
			color: #fff;
			background: $blue;
			border-radius: 6px;
			&:hover {
				background: $blue-darker;
			}
		}
	}
	.filterExpanded {
		h4 {
			margin-top: 30px;
			font-weight: normal;
		}
		.filterItem {
			margin: 15px 0;
		}
	}
	.appliedReset {
		display: flex;
		align-items: center;
		margin-left: 20px;
		flex-grow: 1;
		.filtersApplied {
			color: $dark-grey;
			font-size: 16px;
			font-weight: normal;
			flex-grow: 1;
		}
		.resetButton {
			color: $dark-grey;
			font-size: 16px;
			font-weight: normal;
			cursor: default;
			text-decoration: none;
		}
		.applyButton {
			background: $dark-grey;
			color: #fff;
			border-radius: 6px;
			font-weight: normal;
			cursor: default;
		}
		&[data-active="active"] {
			.filtersApplied {
				color: $blue;
			}
			.applyButton {
				background: $blue;
				cursor: pointer;
				&:hover {
					background-color: $blue-darker;
				}
			}
			.resetButton {
				color: $blue;
				cursor: pointer;
				&:hover {
					color: $blue-darker;
					text-decoration: underline;
				}
			}
		}
		@include media-mobile {
			margin: 10px 0;
			.resetButton {
				padding: 0;
				flex-grow: 1;
				text-align: left;
				font-size: 14px;
			}
		}
	}
	@include media-mobile {
		background: $blue-light-background;
		padding: 5px 15px;
		margin-top: 0;
		display: flex;
		flex-direction: column;

		.filterHeading {
			order: 2;
		}
		.filterExpanded {
			order: 1;
		}
	}
}
