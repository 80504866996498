body {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 1.3em;
    margin: 0;
}

#root {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
}
