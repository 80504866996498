@import "../../../../variables.scss";
.labelSmall {
	text-transform: uppercase;
	font-size: 12px;
	color: $grey-medium;
	margin: 0;
	font-weight: normal;
}
.entities {
	padding: 15px 0;
	.entityList {
		display: flex;
		margin: 20px 0;
		.entityType {
			width: 20%;
			text-align: center;
			flex-grow: 1;
			margin-right: 10px;
			.entityIcon {
				height: 70px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 10px;
			}
			.entityName {
				height: 50px;
			}
			.entityNumber {
				font-size: 24px;
			}
			&[data-entitytype="parliamentaryCommittee"] {
				.entityNumber {
					color: $brown;
				}
			}
			&[data-entitytype="department"] {
				.entityNumber {
					color: $orange;
				}
			}
			&[data-entitytype="hhs"] {
				.entityNumber {
					color: $darker-green;
				}
			}
			&[data-entitytype="otherStateEntity"] {
				.entityNumber {
					color: $dark-grey;
				}
			}
			&[data-entitytype="council"] {
				.entityNumber {
					color: $dark-mustard;
				}
			}
		}
		@include media-tablet {
			.entityType {
				.entityName {
					height: 70px;
				}
			}
		}
		@include media-mobile {
			flex-direction: column;
			.entityType {
				width: 100%;
				text-align: left;
				display: flex;
				align-items: center;
				height: 50px;
				.entityIcon {
					width: 32px;
					height: auto;
					svg {
						width: 32px;
					}
					margin-right: 10px;
				}
				.entityName {
					font-size: 16px;
					flex-grow: 1;
					height: auto;
				}
				.entityNumber {
					font-size: 18px;
				}
			}
		}
	}
}
