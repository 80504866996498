@mixin media-laptop {
    @media screen and (max-width: 1500px) {
        @content;
    }
}

@mixin media-small-desktop {
    @media screen and (max-width: 1350px) {
        @content;
    }
}

@mixin media-tablet {
    @media screen and (max-width: 1150px) {
        @content;
    }
}

@mixin media-mobile {
    @media screen and (max-width: 850px) {
        @content;
    }
}

$blue: rgba(54, 62, 124, 1);
$blue-pastel: rgba(95, 106, 185, 1);
$green: rgba(154, 205, 146, 1);
$dark-green: rgba(103, 184, 92, 1);
$brown: rgba(154, 131, 115, 1);
$dark-grey: rgba(69, 65, 62, 1);
$orange: rgba(227, 98, 58, 1);
$brown-pastel: rgba(199, 182, 174, 1);
$orange-pastel: rgba(241, 166, 145, 1);
$grey-medium: rgba(121, 113, 111, 1);
$grey-medium-light: rgba(183, 180, 189, 1);
$grey-light: rgba(236, 236, 238, 1);
$blue-light-background: rgba(246, 248, 252, 1);
$blue-darker: rgba(30, 38, 83, 1);
$mustard: rgba(254, 153, 0, 1);

$dark-mustard: #a86500;
$darker-green: #42833a;

$summary-left-width: 240px;

h2 {
    font-weight: 300;
}

h3 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: $dark-grey;
}

button {
    color: $dark-grey;
    background-color: $blue-light-background;
    border: none;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.3em;
    text-decoration: none;
    padding: 10px 18px;
    display: block;
    height: auto;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    &[data-active="active"] {
        color: #fff;
        background-color: $blue;
    }
}
button:hover {
    text-decoration: underline;
}
