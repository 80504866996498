@import "../../../../variables.scss";

.labelSmall {
	text-transform: uppercase;
	font-size: 12px;
	color: $grey-medium;
	margin: 0;
	font-weight: normal;
}
.categories {
	padding: 15px 0;
	border-top: 1px solid $grey-light;
	.descriptionAndFilter {
		display: flex;
		padding: 20px 0;
		.description {
			flex-grow: 1;
			padding-right: 20px;
			line-height: 1.5em;
		}
		.dropdown {
			flex-grow: 1;
		}
		@include media-mobile {
			flex-direction: column;
			.dropdown {
				margin-top: 20px;
			}
		}
	}
	.categoryList {
		display: flex;
		flex-direction: column;

		.categoryItem {
			display: flex;
			border-bottom: 1px solid $grey-light;
			align-items: center;
			padding-bottom: 5px;
			margin-bottom: 5px;

			.categoryNumber {
				font-size: 24px;
				color: $blue-darker;
				margin-right: 15px;
				width: 4%;
			}
			.categoryContainer {
				display: flex;
				align-items: center;
				flex-grow: 1;
				.categoryLabelContainer {
					width: 55%;
					padding-right: 10px;
					.categoryLabel {
						font-size: 16px;
						color: $dark-grey;
						border-bottom: 1px dashed $dark-grey;
						&:hover {
							cursor: pointer;
						}
					}
				}
				.chartContainer {
					width: 45%;
					padding: 7px 0;
				}
			}
			.popup {
				opacity: 1 !important;
				width: 200px;
				border: 1px solid $grey-light;
				background: $blue-light-background;
				padding: 10px;
			}
		}

		@include media-mobile {
			.categoryItem {
				align-items: flex-start;
				padding-bottom: 0;
				margin-top: 5px;
				.categoryNumber {
					font-size: 18px;
					margin-right: 20px;
				}
				.categoryContainer {
					flex-direction: column;
					flex-grow: 1;
					align-items: flex-start;
					.categoryLabelContainer {
						width: 100%;
						.categoryLabel {
							font-size: 14px;
						}
					}
					.chartContainer {
						width: 100%;
						padding: 0;
					}
				}
			}
		}
	}
	@include media-mobile {
		flex-direction: column;
		padding: 40px 0;
	}
}
