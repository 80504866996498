@import "../../../../../variables.scss";

.filterDropdown {
	display: flex;
	align-items: baseline;

	.label {
		color: $dark-grey;
		white-space: nowrap;
		font-weight: 500;
		min-width: 240px;
		margin-right: 10px;
	}
	@include media-mobile {
		flex-direction: column;
		.label {
			width: 100%;
			margin-bottom: 10px;
		}
	}
}
