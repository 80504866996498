@import "../../variables.scss";

.chartPercentContainer {
	display: flex;
	align-items: center;
	.percent {
		font-size: 18px;
		width: 60px;
		text-align: right;
		box-sizing: border-box;
		&[data-status="Fully implemented"],
		&[data-status="Partially implemented"] {
			color: $blue-pastel;
		}
		&[data-status="No longer applicable"],
		&[data-status="Not implemented"] {
			color: $brown;
		}
	}
	.chart {
		display: grid;
		width: 100%;
		margin: 10px 0;
		.bar {
			height: 6px;
			text-indent: -10000px;
			box-sizing: border-box;
			&[data-status="Fully implemented"] {
				background: $blue-pastel;
			}
			&[data-status="Partially implemented"] {
				background: #fff;
				border: $blue-pastel solid 1px;
			}
			&[data-status="No longer applicable"] {
				background: $brown-pastel;
			}
			&[data-status="Not implemented"] {
				background: $brown;
			}
			&[data-status="other"] {
				background: $grey-light;
			}
		}
	}
	&[data-na="na"] {
		opacity: 0.3;
	}
}
