@import "../../variables.scss";

.intro {
	margin-bottom: 40px;
	.introHeading {
		font-style: normal;
		font-weight: 400;
		font-size: 36px;
		line-height: 1.5em;
		color: #333;
		margin-bottom: 5px;
	}
	.headingLine {
		margin: 0;
		height: 2px;
		border: none;
		background: #28336e;
		width: 150px;
		margin-bottom: 30px;
	}
	.introPar {
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 1.5em;
		color: $grey-medium;
	}
	@include media-tablet {
		.introHeading {
			font-size: 30px;
		}
		.headingLine {
			width: 100px;
		}
	}
	@include media-mobile {
		.headingLine {
			width: 100px;
		}
	}
	.jumpTo {
		font-size: 16px;
		color: $grey-medium;
		button {
			display: inline;
			font-weight: normal;
			color: $blue;
			padding: 0;
			background: none;
			font-size: 16px;
			text-decoration: underline;
			&:hover {
				color: $blue-darker;
			}
		}
	}
}
