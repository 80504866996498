@import "../../../../variables.scss";

.view {
	@include media-mobile {
		background: $blue-light-background;
		padding: 5px 15px;
		margin-top: 15px;
	}
}
.viewContainer {
	text-align: right;
	.viewEntities {
		display: flex;
		.entityType {
			flex: 1;
			padding: 10px;
			border: none;
			margin-bottom: 5px;
			background: #fff;

			.entityIconContainer,
			.entityLabel {
				display: block;
				font-family: "Montserrat", sans-serif;
				font-size: 16px;
				line-height: 1.3em;
				font-weight: 500;
				color: $blue-darker;
				pointer-events: none;
			}
			.entityLabel {
				padding: 10px 0;
			}
			.entityIconContainer {
				height: 54px;
				.entityIcon {
					display: block;
					height: 100%;
					width: auto;
				}
			}

			&[data-active="active"] {
				border-bottom: 4px solid $blue;
			}
			&[data-active="inactive"] {
				border-bottom: 4px solid #fff;
			}

			@include media-mobile {
				display: flex;
				align-items: center;
				height: 40px;
				text-align: left;
				.entityIconContainer {
					height: auto;
					margin-right: 10px;
					.entityIcon {
						height: auto;
						svg {
							width: 24px;
							height: auto;
							max-height: 24px;
						}
					}
				}
				.entityLabel {
					font-size: 14px;
					padding: 0;
				}
				&[data-active="active"] {
					border-bottom: none;
					border-left: 4px solid $blue;
				}
				&[data-active="inactive"] {
					border-bottom: none;
					border-left: 4px solid #fff;
				}
			}
		}
		@include media-mobile {
			flex-direction: column;
		}
	}
}
