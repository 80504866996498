@import "../../variables.scss";

.chart {
	.bar {
		min-width: 0;
		height: 20px;
		text-indent: -10000px;
		box-sizing: border-box;
		&[data-status="Fully implemented"] {
			background: $blue-pastel;
		}
		&[data-status="Partially implemented"] {
			background: #fff;
			border: $blue-pastel solid 1px;
		}
		&[data-status="No longer applicable"] {
			background: $brown-pastel;
		}
		&[data-status="Not implemented"] {
			background: $brown;
		}
		&[data-status="No recommendations"] {
			background: $grey-light;
		}
	}
	&[data-size="small"] {
		.bar {
			height: 10px;
		}
	}
	&[data-size="tiny"] {
		.bar {
			height: 6px;
		}
	}
}
