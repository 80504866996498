@import "../../variables.scss";
.labelSmall {
	text-transform: uppercase;
	font-size: 12px;
	color: $grey-medium;
	margin: 0;
	font-weight: normal;
}
.summaryHeading {
	display: flex;
	align-items: baseline;

	.summaryHeadingH2 {
		flex-grow: 1;
		color: $blue;
		line-height: 1.3em;
	}

	@include media-mobile {
		flex-direction: column;
	}
}

.summaryContainer {
	border-radius: 10px;
	margin: 10px 0 60px;
	border: 1px solid $grey-light;
	padding: 25px 15px;

	@include media-mobile {
		border: none;
		padding: 10px;
		margin-bottom: 0;
	}
}
