@import "../../../../../variables.scss";

.statusFilter {
	display: flex;
	align-items: baseline;

	.statusFilterLabel {
		color: $dark-grey;
		white-space: nowrap;
		font-weight: 500;
		min-width: 240px;
		margin-right: 10px;
		@include media-mobile {
			margin-right: 0px;
			margin-bottom: 10px;
		}
	}
	.statusFilterOptions {
		flex-grow: 1;
		display: flex;
		.statusFilterItem {
			background: #fff;
			margin-bottom: 5px;
			padding: 15px;
			display: flex;
			border-bottom: 2px solid #fff;
			&[data-active="active"] {
				border-bottom: 2px solid $blue;
			}
			@include media-mobile {
				border-bottom: none;
				border-left: 2px solid #fff;
				padding: 10px;
				align-items: center;
				&[data-active="active"] {
					border-bottom: none;
					border-left: 2px solid $blue;
				}
			}
			.label {
				position: relative;
				margin-bottom: 0;
				&:before {
					content: " ";
					width: 12px;
					height: 12px;
					background: green;
					position: absolute;
					left: 0;
					top: 3px;
					box-sizing: border-box;
				}
				&[data-status="Fully implemented"] {
					padding-left: 18px;
					&:before {
						background: $blue-pastel;
					}
				}
				&[data-status="Partially implemented"] {
					padding-left: 18px;
					&:before {
						background: #fff;
						border: $blue-pastel solid 1px;
					}
				}
				&[data-status="No longer applicable"] {
					padding-left: 18px;
					&:before {
						background: $brown-pastel;
					}
				}
				&[data-status="Not implemented"] {
					padding-left: 18px;
					&:before {
						background: $brown;
					}
				}
			}
			.input {
				min-width: 30px;
			}
			&:hover {
				cursor: pointer;
			}
		}
	}
	@include media-mobile {
		flex-direction: column;
		.statusFilterOptions {
			flex-direction: column;
			width: 100%;
			.label {
				flex-grow: 1;
				font-size: 14px;
			}
		}
	}
}
