@import "../../../variables.scss";

.listContainer {
	margin-bottom: 80px;
}

.listSortBy {
	margin-top: 20px;
	display: flex;
	align-items: baseline;
	justify-content: flex-end;

	.sortByLabel {
		margin-right: 10px;
		font-size: 14px;
	}

	.sortByButtons {
		display: flex;
		button {
			background-color: $blue-light-background;
			font-weight: normal;
			color: #000;
			border: none;
			&[data-active="active"] {
				background-color: $blue;
				color: #fff;
			}
			&:first-child {
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 0;
				border-top-left-radius: 10px;
				border-top-right-radius: 0;
			}
			&:last-child {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 10px;
				border-top-left-radius: 0;
				border-top-right-radius: 10px;
			}
		}
	}
	@include media-mobile {
		flex-direction: column;
		margin-top: 5px;
		.sortByLabel {
			margin: 10px 0;
		}
		.sortByButtons {
			width: 100%;
			button {
				padding: 10px 15px;
				flex-grow: 1;
				font-size: 13px;
			}
		}
	}
}
.listItemsContainer {
	margin: 40px 0;
	.listHeader {
		display: flex;
		align-items: baseline;
		margin-bottom: 10px;
		.listHeaderSummary {
			width: 70%;
			display: flex;
			flex-direction: column;
			font-size: 21px;
			line-height: 1.3em;
			.headerContainer {
				display: flex;
				.summaryEntities {
					font-weight: 600;
					margin-right: 8px;
					text-transform: lowercase;
				}
				.summaryRecommendations:before {
					content: " | ";
				}
			}
			.parliamentaryCommitteeDescription {
				font-size: 16px;
				line-height: 1.5em;
				color: $grey-medium;
				margin-top: 10px;
			}
			.filteredNumber {
				margin-left: 10px;
				color: $blue;
				&:before {
					content: " | ";
				}
			}
			@include media-mobile {
				flex-direction: column;
				line-height: 1.5em;
				width: 100%;
				.headerContainer {
					flex-direction: column;
					.summaryRecommendations:before {
						content: none;
					}
					.filteredNumber:before {
						content: none;
					}
					.filteredNumber {
						margin-left: 0;
					}
				}
			}
		}
		.listHeaderFully,
		.listHeaderNumber {
			width: 15%;
			font-weight: 600;
			@include media-mobile {
				display: none;
			}
		}
		@include media-mobile {
			flex-direction: column;
		}
	}
	.noResults {
		font-size: 16px;
		background: $blue-light-background;
		padding: 15px;
		margin: 10px auto;
		text-align: center;
		.noResultsReset {
			display: inline;
			background: none;
			font-weight: normal;
			color: $blue;
			padding: 0;
			font-size: 16px;
			&:hover {
				color: $blue-darker;
			}
		}
	}
	@include media-mobile {
		margin-top: 15px;
	}
}
