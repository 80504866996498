@import "../../../variables.scss";

.viewFilterContainer {
	background: $blue-light-background;
	padding: 15px;
	@include media-mobile {
		background: none;
		padding: 0;
	}
}

.viewExpando {
	display: flex;
	align-items: center;
	text-align: left;
	width: 100%;
	.chevronDown,
	.chevronUp,
	.filterIcon {
		width: auto;
		height: 22px;
	}
	.chevronDown,
	.chevronUp {
		margin-right: 10px;
	}
	.filterIcon {
		margin-left: 20px;
		flex-grow: 1;
		text-align: right;
	}
	font-weight: normal;
	font-size: 16px;
	color: #fff;
	background: $blue;
	border-radius: 6px;
	&:hover {
		background: $blue-darker;
	}

	@include media-mobile {
		font-size: 13px;
	}
}

.appliedReset {
	margin: 10px 0;
	display: flex;
	.filtersApplied {
		color: $grey-medium-light;
		font-size: 14px;
		flex-grow: 1;
		text-decoration: none;
	}
	.resetButton {
		color: $grey-medium-light;
		font-weight: normal;
		background: none;
		padding: 0;
		text-decoration: none;
	}
	&[data-active="active"] {
		.filtersApplied {
			color: $blue;
			text-decoration: underline;
		}
		.resetButton {
			color: $blue;
			text-decoration: underline;
		}
	}
}
