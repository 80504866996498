@import "../../../variables.scss";

.reports {
	display: flex;
	border-top: 1px solid $grey-light;
	margin-top: 20px;
	padding: 15px;
	.reportsNumber {
		min-width: $summary-left-width;
		display: flex;
		.reportsNumberIcon {
			width: 54px;
			height: 54px;
		}
		.reportsNumberLabel {
			display: flex;
			flex-direction: column;
			padding-left: 15px;
			.number {
				font-size: 36px;
				color: $dark-grey;
				font-weight: 300;
				margin: 10px 0 10px;
			}
		}
	}
	@include media-mobile {
		flex-direction: column;
		padding: 30px 0 0;
	}
}
